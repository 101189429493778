




















































import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({

  name: 'Dashboard',

  data () {
    return {
      pendingComponentInit: false
    };
  },

  computed: {
    ...mapGetters('auth', {
      user: 'user',
      hasActiveSubscription: 'hasActiveSubscription'
    })
  },

  methods: {
    onGoToSubscriptionTiers (): void {
      this.$router.push({ name: 'App:Subscription-Tiers' });
    },

    onGoToVideoCategory (category: string): void {
      this.$router.push({ name: 'App:Video-Categories', params: { category: category } });
    }
  }

});
